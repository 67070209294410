<template>
  <div>
    <wd-navbar class="tarbar">
      <div slot="left" class="middle" @click="$goHome">
        <wd-icon name="arrow-left" />
      </div>
      <div style="white-space: nowrap">
        <span style="white-space: nowrap">{{equipInfo.equipmentName || '--'}}</span>
      </div>
    </wd-navbar>
    <div>
      <div class="header">
        <div class="left">
          <wd-radio-group class="labelGroup" @change="checkChange" v-model="check" shape="button">
            <wd-radio value="1">日</wd-radio>
            <wd-radio value="2">月</wd-radio>
            <wd-radio value="3">年</wd-radio>
          </wd-radio-group>
        </div>
        <div class="right">
          <wd-datetime-picker v-if="check == '1'" @confirm="conform" type="date" :key="1" v-model="date" label="" placeholder="请选择日期"></wd-datetime-picker>
          <wd-datetime-picker v-if="check == '2'" @confirm="conform" type="year-month" :key="2"  v-model="date" label="" placeholder="请选择月份"></wd-datetime-picker>
          <wd-datetime-picker v-if="check == '3'" @confirm="conform" type="year-month" :key="3"  v-model="date" label="" placeholder="请选择年份" :display-format="displayFormat"></wd-datetime-picker>
        </div>
      </div>
      <div class="echarts" id="echarts">

      </div>
    </div>
  </div>
</template>

<script>
  export default {
      data(){
          return {
              date: "",
              check: "1",
              selectDate: "",
              equipInfo: {},
              displayFormat(items) {
                  return `${items[0].label}`
              },
              params: {
                  startDate: "",
                  endDate: "",
                  nodeId: ""
              },
              chartsData: {
                  time: [],
                  data: []
              }
          }
      },
      created() {
          this.date = new Date(this.$moment().subtract(1, "days").format("YYYY-MM-DD"))
          this.selectDate = this.$moment(this.date).format("YYYY-MM-DD")
          this.equipInfo = this.$route.params
      },
      mounted() {
          if( !this.equipInfo.id ) {
              this.$router.push("/power")
          } else {
              this.params.nodeId = this.equipInfo.id
              this.getData();
          }
      },
      methods: {
          getData(){
              this.handleSearchDate()
              this.chartsData = {
                  time: [],
                  data: []
              }
              // 进行http请求
              this.$http.get("/external/bill/getUsageListByNodeId", { params: this.params }).then(res=>{
                  if( res.code == 200 ) {
                    if( res.data && res.data.length > 0 ) {
                        res.data.forEach((item,index)=>{
                          this.chartsData.time.push(item.sname)
                          this.chartsData.data.push(item.usage)
                        })
                    }
                    this.initEcharts()
                  }
              })
          },
          initEcharts(){
            let that = this
            let echarts = this.$echarts.init(document.getElementById("echarts"));
            let option = {
                "color": ["#123dac", "#73e2e2", "#ff7e85", "#9b52ff", "#fac524", "#46caff", "#a1e867", "#10b2b2", "#ec87f7", "#f4905a", "#00baba", "#facf24", "#e89d67", "#23c6c6", "#fa8699", "#40b7fc", "#006d75", "#595959", "#f4764f", "#a640fc", "#fda23f", "#2d7ae4", "#5092ff", "#9351ed", "#8a89fe", "#df89e8", "#2797ff", "#6ad089", "#7c92e8 "],
                "title": {
                    "text": this.equipInfo.equipmentName,
                    "left": "3%",
                    "textStyle": {
                        "fontSize": 14
                    }
                },
                "grid": {
                    "left": "5%",
                    "right": "5%",
                    "top": "14%",
                    "bottom": "16%"
                },
                "xAxis": {
                    "type": "category",
                    "boundaryGap": true,
                    "data": that.chartsData.time,
                    "axisLabel": {
                        rotate: -45,
                        "color": "#a0a9bc",
                        //X轴标签 label 做了特殊处理，防止左右溢出
                        // formatter: (value, index) => {
                        //     if (index === 0 || index === xAxisData.length - 1) {
                        //         return "";
                        //     }
                        //     return value;
                        // },
                    },
                    "axisLine": {
                        "show": false
                    },
                    "axisTick": {
                        "show": false
                    }
                },
                "yAxis": {
                    "type": "value",
                    "axisLabel": {
                        "color": "#a0a9bc",
                        "inside": true,
                        "margin": 0,
                        "verticalAlign": "bottom"
                    },
                    "splitLine": {
                        "lineStyle": {
                            "type": "dashed"
                        }
                    },
                    "axisLine": {
                        "show": false
                    },
                    "axisTick": {
                        "show": false
                    }
                },
                "series": [
                    {
                      "name": "",
                      "data": that.chartsData.data,
                      "type": "line",
                      "smooth": true,
                      "smoothMonotone": "x",
                      "cursor": "pointer",
                      "showSymbol": false,
                      "lineStyle": {
                          "shadowColor": "rgba(18,61,172,0.5)",
                          "shadowBlur": 10
                      }
                    }
                ]
            }
            echarts.setOption(option)
          },
          checkChange(value){
              this.handleDate(value)
              this.getData()
          },
          handleDate(value){
              if( value == "1" ) {
                  this.selectDate = this.$moment(this.date).format("YYYY-MM-DD")
              } else if( value == "2" ) {
                  this.selectDate = this.$moment(this.date).format("YYYY-MM")
              } else if( value == "3" ) {
                  this.selectDate = this.$moment(this.date).format("YYYY")
              }
          },
          conform(value){
              if( this.check == "1" ) {
                  this.selectDate = this.$moment(value).format("YYYY-MM-DD")
              } else if( this.check == "2" ) {
                  this.selectDate = this.$moment(value).format("YYYY-MM")
              } else if( this.check == "3" ) {
                  this.selectDate = this.$moment(value).format("YYYY")
              }
              this.getData()
          },
          handleSearchDate(){
              if( this.check == "1" ) {
                this.params.startDate = this.selectDate
                this.params.endDate = this.selectDate
              } else if( this.check == "2" ) {
                let d   = new Date(Number(this.selectDate.split('-')[0]), Number(this.selectDate.split('-')[1]), 0);
                let day = d.getDate();
                this.params.startDate = this.selectDate+"-01"
                this.params.endDate   = this.selectDate+"-"+day
              } else if( this.check == "3" ) {
                this.params.startDate = this.selectDate+"-01-01"
                this.params.endDate   = this.selectDate+"-12-31"
              }
          },
      }
  }
</script>

<style lang="less" scoped>
  .header{
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    div{
      flex: 1;
    }
    .left{
      .labelGroup{
        display: flex;
        align-items: center;
        justify-content: space-around;
        /deep/ .wd-radio{
          margin-right: 0;
          .wd-radio__label{
            border-radius: 0;
            padding: 3px 10px;
          }
        }
      }
    }
  }
  .echarts{
    width: 100vw;
    height: 300px;
  }
</style>
